<template lang="pug">
.sortContainer
  .sortButtonContainer
    .sortButton
      IconBase(width="60" height="60" viewBox="0 0 24 24" iconName="Copy" iconColor="#777777")
        IconSort
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconSort from "@/components/icons/IconSort.vue"
export default {
  name: 'Sort',
  components: {
    IconBase, IconSort
  },
}
</script>