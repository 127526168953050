<template lang="pug">
.deleteContainer(@click="openDelete")
  .deleteButtonContainer
    .deleteButton
      IconBase(width="60" height="60" viewBox="0 0 24 24" iconName="Copy" iconColor="#777777")
        IconDelete
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconDelete from "@/components/icons/IconDelete.vue"
import DeleteModal from "@/components/ui/Delete/DeleteModal.vue"
export default {
  name: 'Delete',
  components: {
    IconBase, IconDelete, DeleteModal
  },
  props: {
    forget: {
      type: Object,
      required: true
    },
    uuid: {
      type: String,
      required: true
    }
  },
  methods: {
    openDelete(){
      this.$buefy.modal.open({
          parent: this,
          component: DeleteModal,
          hasModalCard: true,
          customClass: 'deleteModal',
          props: {forget: this.forget, uuid: this.uuid}
      })
    }
  }
}
</script>