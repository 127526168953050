<template lang="pug">
.forgetsContainer(:class="{'single': !is2Column, 'double': is2Column}" v-if="fg.show")

  h3.forgetTitle(v-if="is2Column && lTitle.length > 0 && lUrl.length === 0") {{lTitle}}
  h3.forgetTitle(v-if="is2Column && lTitle.length > 0 && lUrl.length > 0")
    a.forgetLink(:href="lUrl" target="_blank")
      span.fgTitleLink {{lTitle}}
      IconBase(viewBox="0 0 21 21" iconName="External" iconColor="#999999" width="12" height="12")
        IconExternal

  .forgetInnerContainer(:class="{'columns is-mobile': is2Column}")

    .forgetFieldContainer(:class="{'column left': is2Column}")
      b-switch.visibleSwitch.leftSwitch(v-model="c1sv" size="is-small" true-value="hide" false-value="show" :outlined="switchOption" :rounded="switchOption")
      h4.forgetKey {{ c1Title }}
      .field.has-addons.fieldWrap
        .control.is-expanded.is-clearfix
          input.input(:type="c1sv === 'show' ? 'text' : 'password'" :value="c1Value")
        .control(@click="execCopy({val: c1Value, fgId: fgUuid})")
          .button.is-primary
            IconBase(viewBox="1 1 21 21" iconName="Copy" iconColor="#FFFFFF" width="20" height="20")
              IconCopy
    .forgetFieldContainer(v-if="is2Column" :class="{'column right': is2Column}")
      b-switch.visibleSwitch(v-model="c2sv" size="is-small" true-value="hide" false-value="show" :outlined="switchOption" :rounded="switchOption")
      h4.forgetKey {{c2Title}}
      .field.has-addons.fieldWrap
        .control.is-expanded.is-clearfix
          input.input(:type="c2sv === 'show' ? 'text' : 'password'" :value="c2Value")
        .control(@click="execCopy({val: c2Value, fgId: fgUuid})")
          .button.is-primary
            IconBase(viewBox="1 1 21 21" iconName="Copy" iconColor="#FFFFFF" width="20" height="20")
              IconCopy

    Sort(v-if="mode === 'sort'" :class="{'mulutiColumn': is2Column}")
    Delete(v-if="mode === 'delete'" :forget="forget" :uuid="fg.uuid" :class="{'mulutiColumn': is2Column}")
    Edit(v-if="mode === 'edit'" :forget="forget" :mode="mode" :class="{'mulutiColumn': is2Column}")
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconCopy from "@/components/icons/IconCopy.vue"
import IconExternal from "@/components/icons/IconExternal.vue"
import Edit from "@/components/ui/Edit/Edit.vue"
import Sort from "@/components/ui/Sort/Sort.vue"
import Delete from "@/components/ui/Delete/Delete.vue"
export default {
  name: 'Forgets',
  props: {
    forget: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  components: {
    IconBase, IconCopy, IconExternal, Edit, Sort, Delete 
  },
  methods: {
    execCopy({val, fgId}){
      this.$store.dispatch('forget/addClipBoardCopyTimes', fgId)
      this.$copyText(val).then((e)=>{
        this.$buefy.toast.open({
          duration: 3000,
          message: `Copied to the clipboard!`,
          position: 'is-bottom',
          type: 'is-dark',
          hasIcon: true
        })
      })
    }
  },
  data(){
    return {
      switchOption: true
    }
  },
  computed: {
    fg(){
      return this.forget
    },
    lTitle: {
      get(){
        return this.forget.title
      },
      set(value){
        this.forget.body.title = value
      }
    },
    lUrl: {
      get(){
        return this.forget.url
      },
      set(value){
        this.forget.body.url = value
      } 
    },
    c1sv: {
      get(){
        return this.forget.body.column1.secret 
      },
      set(value){
        this.forget.body.column1.secret = value
      }
    },
    c2sv: {
      get(){
        return this.forget.body.column2.secret 
      },
      set(value){
        this.forget.body.column2.secret = value
      }
    },
    c1Title: {
      get(){
        return this.forget.body.column1.key 
      },
      set(value){
        this.forget.body.column1.key = value
      }
    },
    c2Title: {
      get(){
        return this.forget.body.column2.key 
      },
      set(value){
        this.forget.body.column2.key = value
      }
    },
    c1Value: {
      get(){
        return this.forget.body.column1.value 
      },
      set(value){
        this.forget.body.column1.value = value
      }
    },
    c2Value: {
      get(){
        return this.forget.body.column2.value 
      },
      set(value){
        this.forget.body.column2.value = value
      }
    },
    fgUuid() {
      return this.forget.uuid
    },
    is2Column(){
      if( 2 === parseInt(this.forget.columnNum) ){
        return true
      }else{
        return false
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.forgetsContainer.single
  .forgetInnerContainer
    .forgetFieldContainer
      .visibleSwitch
        &.leftSwitch
          right: 0px
.forgetsContainer
  margin-bottom: 60px
  .forgetTitle
    font-weight: bolder
    margin-bottom: 10px
    .fgTitleLink
      display: inline-block
      padding-right: 5px
  .forgetInnerContainer
    margin-left: 0px!important
    margin-right: 0px!important
    margin-top: 0px!important
    position: relative
    width: 100%
    height: 100%
    .forgetFieldContainer
      padding: 0!important
      position: relative
      .visibleSwitch
        position: absolute 
        top: -7px
        right: 0px
        &.leftSwitch
          right: 10px
      &.left
        padding-right: 10px!important
      &.right
        padding-left: 10px!important
      .forgetKey
        font-size: 0.8rem
        font-weight: bolder
        text-indent: 0.3rem
</style>
