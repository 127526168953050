<template lang="pug">
.home.mainContainer
  main#main(v-if="forgets.length > 0")
    draggable(v-if="mode === 'sort'" v-model="forgets")
      transition-group(type="transition" :name="'flip-list'")
        Forgets(v-for="f in forgets" :forget="f" :mode="mode" :key="f.uuid")
    Forgets(v-else v-for="f in forgets" :forget="f" :mode="mode" :key="f.uuid")
  main#main(v-else)
    h2.getstartedHeader GET STARTED
    .getstartedContainer.columns.is-mobile
      .getStartedTextContainer.column.is-four-fifths
        p.note Let's get started by clicking on those icons in the upper right! (If you are using mobile, you can find it inside the hamburger menu on the top right!)
      .getStartedImageContainer.column
        img(src="../assets/edit.svg" style="width: 100%")

</template>

<script>
import Forgets from "@/components/ui/Forgets/Forgets.vue"
import draggable from "vuedraggable"

export default {
  name: 'forgetman',
  props: {
    mode: {
      type: String,
      required: true,
      default: 'normal',
    }
  },
  components: {
    Forgets, draggable
  },
  computed: {
    forgets: {
      get(){
        const _forgets = this.$store.getters['forget/getForgets']
        if( _forgets.length === 0){
          this.closeSnackBar()
          this.$store.dispatch("ui/changeMode", 'normal')
        }
        return _forgets
      },
      async set(updateForgets){
        await this.$store.dispatch('forget/updateForgets', updateForgets)
        await this.$store.dispatch("forget/allocateSortNumber")
      }
    }
  },
  methods: {
    openSnackBar(){
      let parentNode = document.getElementById('app')
      let snackContainers = document.querySelectorAll('.notices.is-bottom.has-custom-container')
      if(snackContainers.length === 0){
        let self = this
        self.$buefy.snackbar.open({
          duration: 3000,
          message: "Press 'EXIT' to exit current mode",
          type: 'is-warning',
          actionText: 'EXIT',
          indefinite: true,
          position: 'is-bottom-right',
          onAction: () => {
            self.$store.dispatch("ui/changeMode", 'normal')
            // TODO: タグ絞込検索の状態を 有効化
          }
        })
      }

    },
    closeSnackBar(){
      let parentNode = document.getElementById('app')
      let snackContainers = document.querySelectorAll('.notices.is-bottom.has-custom-container')
      snackContainers.forEach( ele => {
        parentNode.removeChild(ele)
      })
    }
  },
  mounted(){
    // openSnackbar の登録
    this.$EventBus.$on('callOpenSnackBar', this.openSnackBar)
    // close Snackbar の登録
    this.$EventBus.$on('callCloseSnackBar', this.closeSnackBar)

    if(this.mode !== 'normal'){
      this.openSnackBar()
    }
  },
  created(){
    this.$store.dispatch('forget/forgetsShowAll')
  }
}
</script>

<style scoped lang="stylus">
.home
  width: 90%
  margin: 40px auto 0px auto
  padding-bottom: 250px
  .flip-list-move
    transition: transform 0.5s
  #main
    .getstartedHeader
      font-size: 2rem
      font-weight: bold
      letter-spacing: 0.1em
    .getstartedContainer
      .getStartedTextContainer
        .note
          font-size: 1.1rem
          color: #666
          padding-left: 0.2em
          padding-top: 5px
          line-height: 1.7em
</style>
