<template lang="pug">
.editContainer
  .editButtonContainer(@click="openEdit")
    .editButton
      IconBase(width="60" height="60" viewBox="0 0 24 24" iconName="Edit" iconColor="#777777")
        IconEdit
  b-modal(:active.sync="isEditModalActive" has-modal-card full-screen)
    FGEditModal(:forget="forget" :mode="mode")
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconEdit from "@/components/icons/IconEdit.vue"
import FGEditModal from "@/components/ui/Common/FGEditModal.vue"
export default {
  name: 'Edit',
  props: {
    forget: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  components: {
    IconBase, IconEdit, FGEditModal
  },
  data(){
    return {
      isEditModalActive: false,
    }
  },
  methods: {
    openEdit(){
      this.isEditModalActive = true
    }
  }
}
</script>

<style scoped lang="stylus">
.editContainer, .editButtonContainer
  width: 100%
</style>