<template lang="pug">
.modal-card(style="width: auto")
  header.modal-card-head
    p.modal-card-title Really? 
  .modal-card-body(v-if="forget.show")
    h3.forgetTitle(v-if="2 === parseInt(forget.columnNum) && forget.title.length > 0") {{forget.title}}
    dl.forgetContent
      dt.forgetTitle {{forget.body.column1.key}}
      dd.forgetDescription {{forget.body.column1.value}}
    dl.forgetContent(v-if="2 === parseInt(forget.columnNum)")
      dt.forgetTitle {{forget.body.column2.key}}
      dd.forgetDescription {{forget.body.column2.value}}
  footer.modal-card-foot
    button.button(type="button" @click="$parent.close()") Cancel 
    button.button.is-primary(@click="execDelete($parent.close)") Delete 
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    forget: {
      type: Object,
      required: true
    },
    uuid: { // forget 渡ってきているから要らない
      type: String,
      required: true
    }
  },
  methods: {
    async execDelete(close){
      await this.$store.dispatch('forget/deleteForget', {uuid: this.uuid, 'tags': this.forget.tags})
      await this.$store.dispatch("forget/allocateSortNumber")
      close()
    }
  }
}
</script>

<style scoped lang="stylus">
.modal-card-body
  h3.forgetTitle
    font-size: 1.1rem!important
    font-weight: bolder!important
  .forgetContent
    padding-left: 0.3rem
    margin-top: 10px
    .forgetTitle
      font-weight: bolder!important
      font-size: 0.9rem!important
    .forgetDescription
      font-size: 0.9rem!important
      padding-left: 0.3rem
</style>
